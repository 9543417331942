import { ComponentType, lazy, LazyExoticComponent } from 'react';

export interface RouteType {
    path: string;
    exact: boolean;
    component?: LazyExoticComponent<ComponentType<any>>;
    redirect?: string;
    private?: boolean;
}

export const HOME_PAGE_PATH = 'home';
export const PROCEEDING_SEARCH_RESULT_PATH = 'proceedingResult';
export const PROCEEDING_DETAILS_PATH = 'proceedingDetails';
export const PATENT_SEARCH_RESULT_PATH = 'patentResult';
export const PATENT_DETAILS_PATH = 'patentDetails';
export const GENERAL_SEARCH_RESULT_PATH = 'generalResult';
export const GENERAL_DETAILS_PATH = 'generalDetails';
export const OPEN_DATA_PATH = 'openData';

export const APPLICATION_PATHS = [
  HOME_PAGE_PATH,
  PROCEEDING_SEARCH_RESULT_PATH,
  PROCEEDING_DETAILS_PATH,
  PATENT_SEARCH_RESULT_PATH,
  PATENT_DETAILS_PATH,
  GENERAL_SEARCH_RESULT_PATH,
  GENERAL_DETAILS_PATH,
  OPEN_DATA_PATH,
];

export const routes: RouteType[] = [
  {
    path: HOME_PAGE_PATH,
    component: lazy(() => import('../views/SearchFormsView')),
    exact: true,
    private: false,
  },
  {
    path: PROCEEDING_SEARCH_RESULT_PATH,
    component: lazy(() => import('../views/ProceedingSearchResultView')),
    exact: true,
    private: false,
  },
  {
    path: `${PROCEEDING_DETAILS_PATH}/:proceedingNumber`,
    component: lazy(() => import('../views/ProceedingDetailedView')),
    exact: true,
    private: false,
  },
  {
    path: PATENT_SEARCH_RESULT_PATH,
    component: lazy(() => import('../views/PatentSearchResultView')),
    exact: true,
    private: false,
  },
  {
    path: `${PATENT_DETAILS_PATH}/:key/:id`,
    component: lazy(() => import('../views/PatentDetailedView')),
    exact: true,
    private: false,
  },
  {
    path: GENERAL_SEARCH_RESULT_PATH,
    component: lazy(() => import('../views/GeneralSearchResultView')),
    exact: true,
    private: false,
  },
  {
    path: OPEN_DATA_PATH,
    component: lazy(() => import('../views/OpenDataResult')),
    exact: true,
    private: false,
  },
];
