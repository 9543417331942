/* tslint:disable */
/* eslint-disable */
/**
 * LMIS avalik API
 * LMIS avalik API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@wemakesoftware.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {Configuration, ConfigurationParameters} from "./configuration";
import {ClassifierControllerApi, PatentControllerApi, GeneralControllerApi, ProceedingControllerApi, TimelineControllerApi, FilesControllerApi, OpenDataControllerApi } from "./api";
import { APPLICATION_PATHS } from '../routes/config';

export * from "./api";
export * from "./configuration";

const pathSplitted = window.location.pathname?.split("/") ?? ["/"];
const firstSubPath = pathSplitted.find((value) => value && value.length > 0 && APPLICATION_PATHS.indexOf(value) < 0) ?? "";
export const basePath =  `/${firstSubPath}`;
const apiConfig = new Configuration({ basePath: basePath} as ConfigurationParameters);
const ProceedingService = new ProceedingControllerApi(apiConfig, basePath);
const PatentService = new PatentControllerApi(apiConfig, basePath);
const GeneralService = new GeneralControllerApi(apiConfig, basePath);
const ClassifierService = new ClassifierControllerApi(apiConfig, basePath);
const TimeLineService = new TimelineControllerApi(apiConfig, basePath);
const FilesService = new FilesControllerApi(apiConfig, basePath);
const OpenDataService = new OpenDataControllerApi(apiConfig, basePath);

export {
    ProceedingService, ClassifierService, PatentService, GeneralService, TimeLineService, FilesService, OpenDataService
};
