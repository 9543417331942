import { Spin } from 'antd';
import React, { Suspense } from 'react';
import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { RouteType } from './config';

interface RouterProps {
    routes: RouteType[];
    isAuthenticated?: boolean;
}

const CustomRouter = ({ routes }: RouterProps) => {
  const location = useLocation();
  const contextPathEnd = location.pathname.indexOf('/', 1);
  let contextPath: string;
  if (contextPathEnd >= 0) {
    contextPath = location.pathname.substring(0, contextPathEnd + 1);
  } else {
    contextPath = '/';
  }

  return (
    <Suspense fallback={(
      <div className="default-spinner d-flex justify-content-center align-items-center height-50vh">
        <Spin indicator={(<LoadingOutlined spin />)} />
      </div>
    )}
    >
      <Switch>
        {routes?.map((route: RouteType) => (
          <Route
            key={route.path}
            path={`${contextPath}${route.path}`}
            /* eslint-disable-next-line no-nested-ternary */
            render={(props: any) => (
              route.component && <route.component {...props} />
            )}
          />
        ))}
        <Route render={() => <Redirect to="home" />} />
      </Switch>
    </Suspense>
  );
};

export default CustomRouter;
