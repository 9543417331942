import React, { useContext } from 'react';
import { Header } from 'antd/es/layout/layout';
import LogoEst from '../../assets/images/patendiamet_est.png';
import LogoEng from '../../assets/images/patendiamet_eng.png';
import ErfLogo from '../../assets/images/el_regionaalarengu_fond_horisontaalne.svg';
import { LocaleContext } from '../../App';

function PageNavbar() {
  const { locale } = useContext(LocaleContext);

  return (
      <div className="page-main-header mb-5">
          <Header>
              <div className="mt-3 logo-container">
                  {locale === 'et' ? (<img src={LogoEst} alt="LogoEst" />) : (<img src={LogoEng} alt="LogoEng" />)}
                  <img src={ErfLogo} alt="NewLogo" />
              </div>
          </Header>
      </div>
  );
}

export default PageNavbar;
