import React, {
  createContext, useMemo, useState, useEffect,
} from 'react';
import { Layout } from 'antd';
import { IntlProvider } from 'react-intl';
import moment from 'moment';
import PageNavbar from './containers/Navbar/PageNavbar';
import CustomRouter from './routes/CustomRouter';
import { routes } from './routes/config';
import messagesEt from './translations/messages_et.json';
import messagesEn from './translations/messages_en.json';
import { getKeyValue } from './common/util/Util';
import { LOCALES } from './common/constants';

const messages = {
  et: messagesEt,
  en: messagesEn,
};

export const LocaleContext = createContext({
  locale: LOCALES.ET,
  // eslint-disable-next-line no-unused-vars
  setLocale: (locale: string) => {},
});
function App() {
  const [locale, setLocale] = useState<string>(LOCALES.ET);
  const localeValue = useMemo(
    () => ({ locale, setLocale }),
    [locale],
  );
  moment.updateLocale('en-us', {
    week: {
      dow: 1,
    },
  });

  useEffect(() => {
    document.title = getKeyValue(locale)(messages)['public.document.title'];
  }, [locale]);

  return (
    <div className="container">
      <LocaleContext.Provider value={localeValue}>
        <IntlProvider locale={locale} messages={getKeyValue(locale)(messages)}>
          <PageNavbar />
          <Layout.Content>
            <CustomRouter
              routes={routes}
              isAuthenticated
            />
          </Layout.Content>
        </IntlProvider>
      </LocaleContext.Provider>
    </div>
  );
}

export default App;
