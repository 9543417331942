import 'bootstrap/dist/css/bootstrap.min.css';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { BrowserRouter } from 'react-router-dom';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import getCurrentUrl, { siteId } from './config';

const instance = createInstance({
  urlBase: getCurrentUrl(),
  siteId,
});

ReactDOM.render(
  <React.StrictMode>
    {/* @ts-ignore */}
    <MatomoProvider value={instance}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
